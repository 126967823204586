<script>
import { EventBus } from '@/assets/js/helpers.js'
import filters from '@/assets/js/filters.js'
import windowSize from '@/window-size.ts'
export default {
  name: 'NewsPreview',
  props: {
    'newsItem': {
      type: Object,
      required: true
    },
    'previewType': {
      type: String, // basic, highlight
      required: true
    },
    'queryRelated': {
      type: Boolean,
      required: false,
      default: false
    },
    'sizing': {
      type: String, // tall, narrow
      required: false
    },
    'noImage': {
      type: Boolean, // tall, narrow
      required: false
    },
    context: {
      type: String,
      required: false
    },
    maxWidth: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  computed: {
    previewContent: function () {
      var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim
      var wwwPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim
      return this.newsItem.content.replace(new RegExp('/li>', 'g'), '/li>, ').replace(/<[^>]*>/g, '').replace(urlPattern, 'denne adresse').replace(wwwPattern, 'denne adresse')
    },
    publishTime () {
      var timeDiff = Math.abs(new Date().getTime() - new Date(this.newsItem.time).getTime())
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      return diffDays < 400 ? this.$options.filters.timeSince(this.newsItem.time, this.translate) : this.$options.filters.date(this.newsItem.time, '%d.%m.%Y')
    },
    classes: function () {
      var classNames = {}
      classNames[this.previewType] = true
      if (this.sizing) classNames[this.sizing] = true
      return classNames
    },
    relatedEntity: function () {
      return this.queryRelated ? this.newsItem.queryEntity : this.newsItem.mainEntity
    },
    backgroundImage: function () {
      var size = windowSize.size
      if (size === 'xxs' || this.maxWidth === 'xxs') return 'url(' + this.newsItem.imageId.replace('.jpg', '_768.jpg') + ')'
      if (size === 'xs' || this.maxWidth === 'xs') return 'url(' + this.newsItem.imageId.replace('.jpg', '_768.jpg') + ')'
      if (size === 'sm' || this.maxWidth === 'sm') return 'url(' + this.newsItem.imageId.replace('.jpg', '_992.jpg') + ')'
      if (size === 'md' || this.maxWidth === 'md') return 'url(' + this.newsItem.imageId.replace('.jpg', '_1200.jpg') + ')'
      if (size === 'lg' || this.maxWidth === 'lg') return 'url(' + this.newsItem.imageId.replace('.jpg', '_1200.jpg') + ')'
      else return this.newsItem.imageId
    }
  },
  methods: {
    openNewTab (relatedEntity) {
      this.$store.dispatch('getEntity', { lassoId: relatedEntity.lassoId }).then(entity => {
        this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'entity', params: { lassoId: entity.lassoId } }).route)
      })
    },
    navigate () {
      EventBus.$emit('modal', { modalType: 'story', story: this.newsItem, entity: this.relatedEntity, showClose: true })
    }
  },
  filters: {
    monthFormat: function (date) {
      let dateSplit = date.split('-')
      return filters.monthName(parseInt(dateSplit[1]) - 1) + ', ' + dateSplit[0]
    }
  }
}
</script>

<template>
  <div
    class="news-preview content-box"
    :class="classes"
  >
    <template v-if="previewType === 'highlight'">
      <div
        v-if="!noImage"
        class="image"
        :style="{ backgroundImage: backgroundImage }"
        @click="navigate(newsItem)"
      />
      <div class="content">
        <div class="info text-semimuted">
          <a
            href=""
            @click.prevent="openNewTab(relatedEntity)"
          >{{ relatedEntity.name }}</a>, {{ newsItem.time | timeSince(translate) }}
        </div>
        <!-- <h3><router-link exact :to="{ name: 'companyStory', params: { id: newsItem.mainEntity.id, storyTitle: newsItem.slugTitle, storyId: newsItem.uniqueId } }">{{newsItem.prettyHeadline}}</router-link></h3> -->
        <h3>
          <a
            @click="navigate(newsItem)"
            v-html="newsItem.prettyHeadline"
          />
        </h3>
      </div>
    </template>
    <template v-else-if="previewType === 'basic'">
      <div
        v-if="!noImage && newsItem.provider.toLowerCase() !== 'ritzau'"
        class="image"
        :style="{ backgroundImage: backgroundImage }"
        @click.self="navigate(newsItem)"
      />
      <div
        v-if="newsItem.provider.toLowerCase() === 'ritzau'"
        class="image"
        :style="{ backgroundImage: backgroundImage, backgroundColor: '#10253E' }"
        @click.self="navigate(newsItem)"
      >
        <img
          class="via-ritzau"
          src="../../assets/img/svg/ritzau_logo_white.svg?inline"
          alt=""
        >
        <p class="date">
          {{ newsItem.time | date('%d-%m-%Y') | monthFormat }}
        </p>
      </div>
      <!-- <follow-button v-if="!noImage" :title="'FØLG'" :lasso-id="relatedEntity.lassoId" :name="relatedEntity.name" :compact="true" /> -->
      <div class="content">
        <div class="info text-semimuted">
          <a
            href=""
            @click.prevent="openNewTab(relatedEntity)"
          >{{ relatedEntity.name }}</a>, {{ publishTime }}
        </div>
        <h3
          @click="navigate(newsItem)"
          v-html="newsItem.prettyHeadline"
        />
        <p
          class="text-semimuted"
          v-html="$options.filters.linkify(previewContent, true, 120)"
        />
      </div>
    </template>
  </div>
</template>

<style lang="less" scoped>
  // rules to keep in mind
  // narrow (1/4 wide or less) can be visible from sm and up

  .content-box {
    padding: 0;
    border-radius: 8px;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.07);
    font-size: 15px;
  }

  .news-preview {
    position: relative;

    .image {
      background-size: cover;
      cursor: pointer;
      border-radius: 8px 8px 0 0;
      opacity: 1;
      position: relative;
      .via-ritzau {
        width: 180px;
        position: absolute;
        top: 20px;
        left: 30px;
      }
      .date {
        position: absolute;
        top: 55px;
        left: 30px;
        color: #517c92;
      }
    }

    button.follow-button {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .content {
      // min-height: 200px;
      padding: 30px 30px;

      .info {
        font-size: 14px;
      }

      h3 {
        cursor: pointer;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        margin: 10px 0 15px 0;
        word-break: break-word;
        color: #262626;

        a {
          color: inherit;
          &:hover {
            color: #ffa07a;
            text-decoration: none;
          }
        }

        &:hover {
          color: #ffa07a;
        }
      }

      p {
        margin: 0;
        line-height: 26px;
      }
    }

    &.highlight {
      .image {
        min-height: 200px;
      }
      .content {
        h3 {
          font-size: 33px;
        }
      }
    }

    &.basic {
      .image {
        min-height: 200px;
      }
    }
  }

  .news-feed.xxs .news-preview {
    .image {
      border-radius: 0;
    }
  }

  .news-feed.min-xs .news-preview {
    &.highlight {
      .image {
        min-height: 200px;
      }
    }
  }

  .news-feed.min-sm .news-preview {
    &.highlight {
      .image {
        min-height: 275px;
      }
      .content {
        h3 {
          margin-bottom: 0;
        }
      }
    }

    &.basic {
      &.narrow {
        .image {
          min-height: 150px;
        }

        h3 {
          font-size: 17px;
          line-height: 24px;
        }
      }

      &.tall {
        .image {
          min-height: 250px;
        }
      }
    }
  }

  .news-feed.min-md .news-preview {
    &.highlight {
      display: flex;
      align-items: stretch;
      .image {
        width: 50%;
        min-height: 200px;
        border-radius: 3px 0 0 3px;
      }
      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
          margin-bottom: inherit
        }
      }
    }

    &.basic {
      &.narrow {
        .image {
          min-height: 150px;
        }
        h3 {
          font-size: 20px;
        }
      }

      &.tall {
        .image {
          min-height: 300px;
        }
      }
    }
  }

  .news-feed.lg .news-preview {
    &.highlight {
      .image {
        width: 58.333%;
      }
    }
  }
</style>
